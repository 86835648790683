<template>
  <v-container fluid class="smy_wrapper">
    <v-container fluid class="smy_header">
      <v-row class="smy_filter_section">
        <v-col>
        <witnessFilter @applyFilters="applyFilters">
              </witnessFilter>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="smy_contain">
      <v-row>
        <v-col>
          <v-card tile class="tabs-wrapper" flat color="#F6F7F9">
            <v-card-text class="smy_card">
              <v-container fluid class="pa-0 ma-0 mt-0">
                <v-row>
                  <v-col xs=12 class="pb-0 mb-0 mt-0 pt-0">
                    <v-progress-linear
                      color="primary"
                      :indeterminate="witness_data_loading"
                      :active="witness_data_loading"
                      rounded
                      height="3"
                      style="margin-top: -6px;"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
                <v-row >
                  <v-col xs=12 class="pb-0 mb-6 mt-0 pt-0 pl-0 pr-0">
                    <v-tabs
                      v-model="tabs"
                      show-arrows
                      height="80px"
                      background-color="#F6F7F9"
                    >
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab
                        class="primary--text"
                        :disabled="tabs_disabled || no_averages"
                      >
                        Average Event Durations
                      </v-tab>
                      <v-tab
                        class="primary--text"
                        :disabled="tabs_disabled || no_total_time"
                      >
                        Event Total Durations
                      </v-tab>
                      <v-tab
                        class="primary--text"
                        :disabled="tabs_disabled || no_counts"
                      >
                        Event Counts
                      </v-tab>
                      <v-tab
                        class="primary--text"
                        :disabled="tabs_disabled || no_raw_data"
                      >
                        Raw Data
                      </v-tab>
                    </v-tabs>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mb-0" style="margin-top: -12px;">
        <witnessSummary showDescription v-show="tabs !== 3" :mode="summaryMode" :requestDetails="witnessFilterValues" addHistoricalResultToStore></witnessSummary>
      </v-row>
      <v-row dense>
        <v-col v-if="multiClinic && tabs !== 3" cols=12 sm=12 md=12 lg=12 xl=6 offset=0 class="">
          <witnessPieControl
          :dl_button="true"
          dl_color="rgba(255, 94, 99, 0.6)"
          @dl="ClinicTotalDataCSV"
          :loading="ClinicComparisonData.loading"
          :data="ClinicComparisonData.data"
          :metadata="ClinicComparisonData.metadata"
          itemKey="clinicid"
          nameKey="clinic_comparison_name"
          :modeKey="modeKey"
          :autoResize="true"
          :multiClinic="multiClinic"
          card_title_base="Clinic">
          </witnessPieControl>
        </v-col>
        <v-col v-if="tabs !== 3 && multiWitnessPoint" cols=12 xs=12 sm=12 md=12 lg=12 xl=6 offset=0 class="">
          <witnessPieControl
          :dl_button="true"
          dl_color="rgba(54, 140, 225, 0.6)"
          @dl="WPDataCSV"
          :loading="WitnesspointData.loading"
          :data="WitnesspointData.data"
          :metadata="WitnesspointData.metadata"
          itemKey="witness_point_name"
          nameKey="witness_point_name"
          :modeKey="modeKey"
          :autoResize="true"
          :multiClinic="multiClinic"
          card_title_base="Witness Point">
          </witnessPieControl>
        </v-col>
        <v-col v-if="tabs !== 3 && multiLocation" cols=12 sm=12 md=12 lg=12 xl=6 offset=0 class="">
          <witnessPieControl
          :dl_button="true"
          dl_color="rgba(48, 239, 130, 0.6)"
          @dl="LOCDataCSV"
          :loading="WorkareaData.loading"
          :data="WorkareaData.data"
          :metadata="WorkareaData.metadata"
          itemKey="uniqueKey"
          nameKey="witness_location_name"
          :modeKey="modeKey"
          :autoResize="true"
          :multiClinic="multiClinic"
          card_title_base="Location">
          </witnessPieControl>
        </v-col>
        <v-col v-if="tabs !== 3 && multiOperator" cols=12 sm=12 md=12 lg=12 xl=6 offset=0 class="">
          <witnessPieControl
          :dl_button="true"
          dl_color="rgba(255, 94, 99, 0.6)"
          @dl="OPDataCSV"
          :loading="OperatorData.loading"
          :data="OperatorData.data"
          :metadata="OperatorData.metadata"
          itemKey="uniqueKey"
          nameKey="witness_operator_name"
          :modeKey="modeKey"
          :autoResize="true"
          :multiClinic="multiClinic"
          card_title_base="Operator">
          </witnessPieControl>
        </v-col>
        <v-col v-if="tabs !== 3 && dateFilter.jumpAmount" cols=12 sm=12 md=12 lg=12 xl=6 offset=0>
          <witness-historical
          :xAxisTitlePeriod="periodForHistoricalChart"
          :loading="formattedHistoricalChartData.loading"
          :data="WitnesspointData.data"
          :metadata="formattedHistoricalChartData"
          nameKey="witness_point_name"
          :modeKey="modeKey"
          :autoResize="true"
          card_title_base="Witness Point"
          ></witness-historical>
        </v-col>
        <v-col v-if="tabs == 0" cols=12 sm=12 md=12 lg=12 xl=6 offset=0>
          <witness-histogram
          :loading="WitnesspointData.loading"
          :metadata="WitnesspointData.metadata"
          nameKey="witness_point_name"
          :modeKey="modeKey"
          :autoResize="true"
          card_title_base="Witness Point"
          ></witness-histogram>
        </v-col>
        <v-col v-show="tabs === 3" cols=12 md=12 lg=12 xl=12 offset=0>
          <witnessDataTable
          :clinicIds="witnessFilterValues.clinicIds"
          :nonProcedureEvents="witnessFilterValues.nonProcedureEvents"
          :events="witnessFilterValues.events"
          :locations="witnessFilterValues.locations"
          :operators="witnessFilterValues.operators"
          :startDate="witnessFilterValues.startDate"
          :endDate="witnessFilterValues.endDate"
          :updateDataTable="updateDataTable"
          @error="handleError"
          @tableUpdated="tableUpdated"
          >
          </witnessDataTable>
        </v-col>
      </v-row>
  </v-container>
  </v-container>
</template>

<style scoped>
* >>> .v-menu__content--auto {
  max-height: 400px !important;
}
.v-tab.v-tab--active {
  color: #005CAB!important;
}
.v-tab {
  color: #909090!important;
  text-transform: none;
  font-size: 16px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: -3px;
}
.v-tab:hover {
  background-color: none;
}
</style>

<script>
import witnessEventsViewModel from '@/view-models/witness-events-view-model';

export default witnessEventsViewModel;

</script>
