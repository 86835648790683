import apiEndpoints from '@/models/common/api-endpoints';
import dayjs from 'dayjs';
import generalFunctions from '@/models/common/general-functions';
import { mapGetters } from 'vuex';
import witnessDataTable from '@/components/WitnessRawDataTable';
import witnessDatePicker from '@/components/WitnessDatePicker';
import witnessFilter from '@/components/WitnessFilter';
import witnessHistogram from '@/components/WitnessHistogram';
import witnessHistorical from '@/components/WitnessHistorical';
import witnessPieControl from '@/components/WitnessPieControl';
import witnessSummary from '@/components/WitnessSummary';


const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export default {
  name: 'witness_events',
  components: {
    witnessPieControl,
    witnessHistorical,
    witnessHistogram,
    witnessDataTable,
    witnessSummary,
    witnessDatePicker,
    witnessFilter
  },
  data () {
    return {
      witnessFilterValues: {
        clinicIds: null,
        nonProcedureEvents: {
          includeAssigns: true,
          includeDiscards: true,
          includeMismatches: true
        },
        events: null,
        locations: null,
        operators: null,
        startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      },
      updateDataTable: false,
      datePickerOutput: undefined,
      menu_open: false,
      // Guide to the state structure:
      // - Each part of the page which relies on external data has a state
      // - Each part has a loading, error and ready state
      // - The ready state is set when that part of the page is not busy (i.e. all api calls completed)
      //    This does NOT mean that there are no errors, each part should test dependencies for error states
      witnessDateRange: {
        loading: false,
        error: false,
        ready: true,
        data: {
          enddate: dayjs().format('YYYY-MM')
        }
      },
      WitnessNames: {
        loading: true,
        error: false,
        ready: false
      },
      WitnesspointData: {
        loading: true,
        error: false,
        ready: false,
        data: [],
        metadata: {}
      },
      formattedHistoricalChartData: {
        historical: {},
        loading: true
      },
      WorkareaData: {
        loading: true,
        error: false,
        ready: false,
        data: [],
        metadata: {}
      },
      OperatorData: {
        loading: true,
        error: false,
        ready: false,
        data: [],
        metadata: {}
      },
      ClinicComparisonData: {
        loading: false,
        error: false,
        ready: false,
        data: [],
        metadata: {}
      },
      pie_layout: {
        margin: {
          l: 5,
          r: 5,
          b: 5,
          t: 5,
          pad: 0
        },
        showlegend: false,
        direction: 'clockwise',
        hoverinfo: 'all'
      },
      pie_options: {
        responsive: true,
        displayModeBar: false,
        showSendToCloud: false,
        showEditInChartStudio: false,
        showLink: false,
        displaylogo: false
      },
      errorMessage: null,
      showError: false,
      tabs_disabled: false,
      tabs: null,
      no_counts: false,
      no_averages: false,
      no_total_time: false,
      no_raw_data: false,
      returnedData: null
    };
  },

  // ************
  // * Computed *
  // ************

  computed: {
    ...mapGetters({
      currentClinic: 'currentClinic',
      allClinics: 'allClinics',
      deviceInfo: 'allDeviceInfo',
      witnessInstallList: 'witnessInstallList',
      historicalChartData: 'historicalChartData',
      dateFilter: 'dateFilter'
    }),
    multiClinic: function () {
      if (this.witnessFilterValues.clinicIds != null && this.witnessFilterValues.clinicIds.length == 1) {
        return false;
      }
      return true;
    },
    multiWitnessPoint: function () {
      if (this.witnessFilterValues.events != null && this.witnessFilterValues.events.length == 1) {
        return false;
      }
      return true;
    },
    multiLocation: function () {
      if (this.witnessFilterValues.locations != null && this.witnessFilterValues.locations.length == 1) {
        return false;
      }
      return true;
    },
    multiOperator: function () {
      if (this.witnessFilterValues.operators != null && this.witnessFilterValues.operators.length == 1) {
        return false;
      }
      return true;
    },
    summaryMode: function () {
      switch (this.tabs) {
        case 0:
          return 'avgDuration';
        case 1:
          return 'total';
        case 2:
          return 'count';
      }
      return undefined;
    },
    modeKey: function () {
      if (this.tabs === 0) {
        return 'average_duration';
      } else if (this.tabs === 1) {
        return 'total_time';
      } else if (this.tabs === 2) {
        return 'count';
      } else {
        return 'raw_data';
      }
    },
    witness_data_loading: function () {
      return (this.WitnesspointData.loading || this.WorkareaData.loading || this.OperatorData.loading);
    },
    periodForHistoricalChart: function () {
      if (this.dateFilter.jumpPeriod == 'day') {
        return this.dateFilter.jumpAmount + ' days';
      } else {
        return this.dateFilter.jumpPeriod;
      }
    }
  },

  // *********
  // * Watch *
  // *********

  watch: {
    historicalChartData: function (newVal, oldVal) {
      if (newVal?.length > 0) {
        var historicalUpdatedData = this.formatWitnessPointHistoricalData(newVal);
        this.formattedHistoricalChartData.historical = historicalUpdatedData;
        this.formattedHistoricalChartData.loading = false;
      }
    }
  },
  mounted () {
  },

  // ***********
  // * Methods *
  // ***********

  methods: {
    computeMetadata: function (response_array) {
      var max_count = 0;
      var max_duration_count = 0;
      var max_average_duration = 0;
      var max_time_total = 0;
      var total_count = 0;
      var total_duration_count = 0;
      var total_average_duration = 0;
      var total_time_total = 0;
      var avg_average_duration = 0;

      response_array.forEach((row, i) => {
        if ('count' in row) {
          if (max_count < row['count']) {
            max_count = row['count'];
          }
          total_count += row['count'];
        }
        if ('countwithduration' in row) {
          if (max_duration_count < row['countwithduration']) {
            max_duration_count = row['countwithduration'];
          }
          total_duration_count += row['countwithduration'];
        }
        if ('average_duration' in row) {
          if (max_average_duration < row['average_duration']) {
            max_average_duration = row['average_duration'];
          }
          total_average_duration += row['average_duration'] * row['countwithduration'];
        }
        if ('total_time' in row) {
          if (max_time_total < row['total_time']) {
            max_time_total = row['total_time'];
          }
          total_time_total += row['total_time'];
        }

      });

      if (total_average_duration > 0 && total_duration_count > 0) {
        // avg_average_duration in minutes
        // avg_average_duration = (total_average_duration / 60) / total_duration_count;
        avg_average_duration = (total_average_duration) / total_duration_count;
      }
      return {
        'max_count': max_count,
        'max_duration_count': max_duration_count,
        'max_average_duration': max_average_duration,
        'max_time_total': max_time_total,
        'total_count': total_count,
        'total_duration_count': total_duration_count,
        'total_average_duration': total_average_duration,
        'total_time_total': total_time_total,
        'avg_average_duration': avg_average_duration
      };
    },
    reformatAndSortResponseArray: function (response_array) {
      response_array.forEach((element, i) => {
        const color = generalFunctions.assignColorFromIndex(i);
        element.color = color;
        // element.color_count = color;
        // element.color_total_time = color;
        // element.color_average_duration = color;
        
        element.average_duration = element.averagetimeseconds;
        delete element.averagetimeseconds;
        element.total_time = element.totaltimeseconds;
        delete element.totaltimeseconds;
      });

      response_array.sort((a, b) => b.average_duration - a.average_duration);
      response_array.forEach((element, i) => {
        element.color_average_duration = generalFunctions.assignColorFromIndex(i);
      });

      response_array.sort((a, b) => b.total_time - a.total_time);
      response_array.forEach((element, i) => {
        element.color_total_time = generalFunctions.assignColorFromIndex(i);
      });

      response_array.sort((a, b) => b.count - a.count);
      response_array.forEach((element, i) => {
        element.color_count = generalFunctions.assignColorFromIndex(i);
      });


      return response_array;
    },
    updateWorkareaData: function (response_array) {
      response_array.forEach((element, i) => {
        element['witness_location_name'] = element.location;
        element.uniqueKey = element.clinicid + element.location;
        delete element.location;
      });
      const formatted_response = this.reformatAndSortResponseArray(response_array);

      this.WorkareaData.loading = false;
      this.WorkareaData.error = false;
      this.WorkareaData.data = formatted_response;
      this.WorkareaData.metadata = this.computeMetadata(formatted_response);
    },
    updateOperatorData: function (response_array) {
      response_array.forEach((element, i) => {
        element['witness_operator_name'] = element.operator;
        element.uniqueKey = element.clinicid + element.operator;
        delete element.operator;
      });
      const formatted_response = this.reformatAndSortResponseArray(response_array);

      this.OperatorData.loading = false;
      this.OperatorData.error = false;
      this.OperatorData.data = formatted_response;
      this.OperatorData.metadata = this.computeMetadata(formatted_response);
    },
    updateClinicComparisonData: function (response_array) {
      response_array.forEach((element, i) => {
        element['clinic_comparison_name'] = generalFunctions.getClinicNameFromClinicGuid(element.clinicid);
      });
      const formatted_response = this.reformatAndSortResponseArray(response_array);

      this.ClinicComparisonData.loading = false;
      this.ClinicComparisonData.error = false;
      this.ClinicComparisonData.data = formatted_response;
      this.ClinicComparisonData.metadata = this.computeMetadata(formatted_response);
    },
    updateWitnessPointDataIncludingHistogram: function (response_array, histogramData) {
      response_array.forEach((element, i) => {
        if (element.witnesspoint !== null) {
          element['witness_point_name'] = element.witnesspoint;
        } else {
          element['witness_point_name'] = element.eventtype;
        }
        delete element.eventtype;
        delete element.witnesspoint;
      });
      const formatted_response = this.reformatAndSortResponseArray(response_array);
      this.updateWitnessPointDataUsingClinicAverages(formatted_response);

      this.WitnesspointData.data = formatted_response;
      this.WitnesspointData.metadata = this.computeMetadata(formatted_response);
      this.WitnesspointData.metadata.histogram = histogramData;
      this.WitnesspointData.metadata.histogram['avg_average_duration'] = this.WitnesspointData.metadata['avg_average_duration'];
      this.WitnesspointData.loading = false;
      this.WitnesspointData.error = false;
    },
    updateWitnessPointDataUsingClinicAverages: function (response_array) {
      for ( let wp of this.WitnesspointData.data ) {
        for ( let clinicwp of response_array ) {
          if (wp['witness_point_name'].toUpperCase().trim() === clinicwp['witness_point_name'].toUpperCase().trim()) {
            wp['clinic_average_duration'] = clinicwp['averagetimeseconds'];
          }
        }
        if (wp['clinic_average_duration'] > this.WitnesspointData.metadata['max_average_duration']) {
          this.WitnesspointData.metadata['max_average_duration'] = wp['clinic_average_duration'];
        }
      }
    },
    formatWitnessPointHistoricalData: function (response_array) {
      var historical = { dates: [], count: [], count_with_duration: [], average_duration: [], total_time: [] };
      var arrayCopy = generalFunctions.deepCopy(response_array);
      arrayCopy.reverse();
      // Remove first entry as want to have the 12 most recent months, 4 most recent quarters etc.
      arrayCopy.shift();
      for( let element of arrayCopy ) {
        historical.dates.push(element.date);
        historical.count.push(element.count);
        historical.count_with_duration.push(element.countwithduration);
        historical.average_duration.push(element.averagetimeseconds);
        historical.total_time.push(element.totaltimeseconds);
      }

      return historical;
    },
    calculateWitnessPointHistogramData: function (response_array, bin_size = 1) {
      var histogram = { binSize: null, avg_average_duration: null, binNum: [], binVal: [], count: [] };
      histogram.binSize = bin_size;
      for( let element of response_array ) {
        histogram.binNum.push(element.durationminutes);
        histogram.binVal.push(element.durationminutes);
        histogram.count.push(element.count);
      }

      return histogram;
    },
    retrieveAndFormatWitnessData: async function () {
      const byLocationStatus = await apiEndpoints.getAllWitnessEvents(this.witnessFilterValues.clinicIds, this.witnessFilterValues.nonProcedureEvents, this.witnessFilterValues.events, this.witnessFilterValues.locations, this.witnessFilterValues.operators, this.witnessFilterValues.startDate, this.witnessFilterValues.endDate, 'location', null, null, 'eventsViewRetrieveAndFormatWitnessDataByLocationStatus');
      const byOperatorStatus = await apiEndpoints.getAllWitnessEvents(this.witnessFilterValues.clinicIds, this.witnessFilterValues.nonProcedureEvents, this.witnessFilterValues.events, this.witnessFilterValues.locations, this.witnessFilterValues.operators, this.witnessFilterValues.startDate, this.witnessFilterValues.endDate, 'operator', null, null, 'eventsViewRetrieveAndFormatWitnessDataByOperatorStatus');
      const byWitnessPointStatus = await apiEndpoints.getAllWitnessEvents(this.witnessFilterValues.clinicIds, this.witnessFilterValues.nonProcedureEvents, this.witnessFilterValues.events, this.witnessFilterValues.locations, this.witnessFilterValues.operators, this.witnessFilterValues.startDate, this.witnessFilterValues.endDate, 'witnesspoint', null, null, 'eventsViewRetrieveAndFormatWitnessDataByWitnessPointStatus');
      const byDurationStatus = await apiEndpoints.getAllWitnessEvents(this.witnessFilterValues.clinicIds, this.witnessFilterValues.nonProcedureEvents, this.witnessFilterValues.events, this.witnessFilterValues.locations, this.witnessFilterValues.operators, this.witnessFilterValues.startDate, this.witnessFilterValues.endDate, 'duration', null, null, 'eventsViewRetrieveAndFormatWitnessDataByDurationStatus');
      if (this.multiClinic) {
        const byClinicStatus = await apiEndpoints.getAllWitnessEvents(this.witnessFilterValues.clinicIds, this.witnessFilterValues.nonProcedureEvents, this.witnessFilterValues.events, this.witnessFilterValues.locations, this.witnessFilterValues.operators, this.witnessFilterValues.startDate, this.witnessFilterValues.endDate, 'clinic', null, null, 'eventsViewRetrieveAndFormatWitnessDataByClinicStatus');
        if (byClinicStatus.result && byClinicStatus.mostRecentWithCancelKey) {
          this.updateClinicComparisonData(byClinicStatus.data);
        }
        else if (!byClinicStatus.error.isCancel) {
          this.ClinicComparisonData.loading = false;
        }
      }

      if (byLocationStatus.result && byLocationStatus.mostRecentWithCancelKey) {
        this.updateWorkareaData(byLocationStatus.data);
      }
      else if (!byLocationStatus.error.isCancel) {
        this.WorkareaData.loading = false;
      }
      
      if (byOperatorStatus.result && byOperatorStatus.mostRecentWithCancelKey) {
        this.updateOperatorData(byOperatorStatus.data);
      }
      else if (!byOperatorStatus.error.isCancel) {
        this.OperatorData.loading = false;
      }

      var histogramData = {};
      if (byDurationStatus.result && byDurationStatus.mostRecentWithCancelKey) {
        histogramData = this.calculateWitnessPointHistogramData(byDurationStatus.data);
      }
      
      if (byWitnessPointStatus.result && byWitnessPointStatus.mostRecentWithCancelKey) {
        this.updateWitnessPointDataIncludingHistogram(byWitnessPointStatus.data, histogramData);
      }
      else if (!byWitnessPointStatus.error.isCancel) {
        this.WitnesspointData.loading = false;
      }      
    },
    retrieveWitnessData: function () {
      this.WitnesspointData.loading = true;
      this.formattedHistoricalChartData.loading = true; 
      this.WitnesspointData.error = false;
      this.WitnesspointData.data = [];
      this.WitnesspointData.metadata = {};

      this.OperatorData.loading = true;
      this.OperatorData.error = false;
      this.OperatorData.data = [];
      this.OperatorData.metadata = {};

      this.WorkareaData.loading = true;
      this.WorkareaData.error = false;
      this.WorkareaData.data = [];
      this.WorkareaData.metadata = {};
      
      this.ClinicComparisonData.loading = true;
      this.ClinicComparisonData.error = false;
      this.ClinicComparisonData.data = [];
      this.ClinicComparisonData.metadata = {};

      this.retrieveAndFormatWitnessData();
    },
    convertToCSV: function (array, headerRow = null) {
      if (!headerRow) {
        // Create the header row dynamically from object keys
        headerRow = Object.keys(array[0]);
      }

      // Loop over the array and output an array in headerRow order for each object
      let csv = array.map(row => {
        return headerRow.map(name => {
          if (typeof row[name] === 'string' || row[name] instanceof String) {
            return '"' + row[name] + '"';
          }
          return row[name];
        });
      });

      // Put the header row back as the first entry in the array
      csv = [headerRow].concat(csv);

      // Generate and return CSV data from the 2d array
      return csv.map(it => {
        return Object.values(it).toString();
      }).join('\n');
    },
    removeSuperfluousColumnsAndAddClinicName: function (payload) {
      const stripped_data = JSON.parse(JSON.stringify(payload));

      stripped_data.forEach(function (item) {
        if (item.color) {
          delete item.color;
        }
        if (item.color_average_duration) {
          delete item.color_average_duration;
        }
        if (item.color_count) {
          delete item.color_count;
        }
        if (item.color_total_time) {
          delete item.color_total_time;
        }
        if (item.countwithduration) {
          item.count_with_duration = item.countwithduration;
          delete item.countwithduration;
        }
        if (item.clinicid) {
          item.clinic_name = generalFunctions.getClinicNameFromClinicGuid(item.clinicid);
          delete item.clinicid;
        }
      });
      return stripped_data;
    },
    startDownload: function (csv_text, file_name) {
      if (window.navigator.msSaveOrOpenBlob) {
        const blob = new Blob(['\ufeff' + decodeURIComponent(encodeURI(csv_text))], {
          type: 'text/csv;charset=utf-8;'
        });
        navigator.msSaveBlob(blob, file_name + '.csv');
      } else {
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + '\ufeff' + encodeURI(csv_text);
        hiddenElement.target = '_blank';
        hiddenElement.download = file_name + '.csv';
        hiddenElement.click();
        hiddenElement.remove();
      }
    },
    WPDataCSV: function () {
      const data = this.removeSuperfluousColumnsAndAddClinicName(this.WitnesspointData.data);

      const csv = this.convertToCSV(data, ['witness_point_name', 'average_duration', 'total_time', 'count', 'count_with_duration']);

      this.startDownload(csv, 'witnesspoints');
    },
    LOCDataCSV: function () {
      const data = this.removeSuperfluousColumnsAndAddClinicName(this.WorkareaData.data);

      const csv = this.convertToCSV(data, ['clinic_name', 'witness_location_name', 'average_duration', 'total_time', 'count', 'count_with_duration']);

      this.startDownload(csv, 'locations');
    },
    OPDataCSV: function () {
      const data = this.removeSuperfluousColumnsAndAddClinicName(this.OperatorData.data);

      const csv = this.convertToCSV(data, ['clinic_name', 'witness_operator_name', 'average_duration', 'total_time', 'count', 'count_with_duration']);

      this.startDownload(csv, 'operators');
    },
    ClinicTotalDataCSV: function () {
      const data = this.removeSuperfluousColumnsAndAddClinicName(this.ClinicComparisonData.data);

      const csv = this.convertToCSV(data, ['clinic_comparison_name', 'average_duration', 'total_time', 'count', 'count_with_duration']);

      this.startDownload(csv, 'witnesspointsbyclinic');
    },
    handleError: function (error) {
      this.errorMessage = error.customMessage;
      this.showError = true;
    },
    tableUpdated: function () {
      this.updateDataTable = false;
    },
    applyFilters: async function (clinicIds, nonProcedureEvents, events, locations, operators, startDate, endDate) {
      const originalValues = generalFunctions.deepCopy(this.witnessFilterValues);
      
      this.witnessFilterValues.clinicIds = clinicIds;
      this.witnessFilterValues.nonProcedureEvents = nonProcedureEvents;
      this.witnessFilterValues.events = events;
      this.witnessFilterValues.locations = locations;
      this.witnessFilterValues.operators = operators;
      this.witnessFilterValues.startDate = startDate;
      this.witnessFilterValues.endDate = endDate;

      if (JSON.stringify(originalValues) !== JSON.stringify(this.witnessFilterValues)) {
        this.updateDataTable = true;
        this.retrieveWitnessData();
      }
    }
  }
};
